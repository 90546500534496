:root {
  --primary-color: #CA4F34;
  --white-color: #ffffff;
  --black-color: #000000;
}

.sildecontainer {
  display: flex;
  background-color: var(--white-color);
}
.sildecontainer .one-review,
.sildecontainer .two-review {
  padding: 20px;
  margin: 10px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--white-color);
}
.sildecontainer .one-review .review,
.sildecontainer .two-review .review {
  font-size: 50px;
  font-weight: 600;
  color: var(--primary-color);
  margin-top: 0px;
}
.sildecontainer .one-review .qoute-img,
.sildecontainer .two-review .qoute-img {
  height: 50px;
  position: absolute;
  margin-top: 10px;
  text-align: start;
  left: 450px;
  top: 270px;
  z-index: 100;
}
.sildecontainer .one-review .docName,
.sildecontainer .two-review .docName {
  font-size: 2rem;
  font-weight: bold;
  margin-top: 90px;
  color: var(--black-color);
}
.sildecontainer .one-review .preivew,
.sildecontainer .two-review .preivew {
  margin-top: 10px;
  font-style: italic;
  width: 600px;
  z-index: 110;
}
.sildecontainer .one-review .pName,
.sildecontainer .two-review .pName {
  margin-top: 10px;
  font-size: 18px;
  letter-spacing: 3px;
  color: var(--black-color);
}

@media (max-width: 1024px) {
  .sildecontainer .one-review .qoute-img, .sildecontainer .two-review .qoute-img {
    left: 200px;
    top: 270px;
  }
}
@media (max-width: 768px) {
  .sildecontainer .one-review .review, .sildecontainer .two-review .review {
    font-size: 40px;
    margin-top: 0px;
  }
  .sildecontainer .one-review .docName, .sildecontainer .two-review .docName {
    margin-top: 10px;
    z-index: 105;
  }
  .sildecontainer .one-review .qoute-img, .sildecontainer .two-review .qoute-img {
    left: 30px;
    top: 200px;
  }
  .sildecontainer .one-review .preivew, .sildecontainer .two-review .preivew {
    width: 250px;
    text-align: center;
  }
}/*# sourceMappingURL=Review.css.map */