:root {
  --primary-color: #CA4F34;
  --white-color: #ffffff;
  --black-color: #000000;
}

.teamSection {
  padding-top: 1rem;

  .main-heading {
    color: var(--primary-color);
    line-height: 1.5rem;
    padding-bottom: .5rem;
    font-size: 35.3px;
    font-weight: 700;
    letter-spacing: -0.78px;
    line-height: 53px;
  }

  .doctorTeam {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8rem;
    margin-left: 10px;
    margin-right: 10px;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;


    .doctor-1 {
      display: flex;
      flex-direction: column;
      // border: 1px solid var(--black-color);
      // padding: 2rem 0;
      overflow: hidden;
      border-radius: 1rem;
      box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.45);
      height: 740px;
      width: 400px;


      .doctorNameSection-1 {
        height: 600;
        width: 100%;
        position: relative;
        overflow: hidden;
        display: grid;
        align-items: start;

        .veerImg {
          height: 400px;
          width: 100%;
          object-fit: cover;
          overflow: hidden;
          // background-position: center;
          // background-size: cover;
          // background-repeat: no-repeat;
        }

        .doctorName-1 {
          position: relative;

          h3 {
            font-size: 2rem;
            font-weight: 600;
            color: var(--primary-color);
            width: max-content;
            margin-top: 10px;
            margin-left: 10px;
            margin-bottom: 0;
          }
        }

        .specialist-1 {
          position: relative;

          h5 {
            font-size: 1.4rem;
            font-weight: 500;
            color: var(--primary-color);
            width: max-content;
            margin-top: 0px;
            margin-left: 10px;
            margin-bottom: 0;

          }
        }
      }

      .detailsOfDoctor-1 {
        p {
          color: var(--black-color);
          font-size: 17px;
          font-weight: 400;
          margin-left: 10px;
          margin-top: opx;
          margin-bottom: 0;
        }

      }
    }
  }
}

@media (max-width:768px) {
  .teamSection {
    .main-heading {
      font-size: 25px;
      margin-left: 10px;
    }

    .doctorTeam {
      display: grid;
      gap: 2rem;
      align-items: center;

      .doctor-1 {
        width: 100%;

        .doctorNameSection-1 {
          .veerImg {
            // background-position: center;
            // background-size: cover;
            // background-repeat: no-repeat;
            // object-fit: contain;
            // object-fit: cover;
            // overflow: hidden;
            max-width: 100%;
            max-height: 100%;
          }

          .doctorName-1 {
            h3 {
              font-size: 1.5rem;
            }
          }

          .specialist-1 {
            h5 {
              font-size: 15px;
            }
          }
        }

        // .detailsOfDoctor-1{
        //   p{

        //   }
        // }
      }
    }
  }
}