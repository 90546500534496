:root {
  --primary-color: #CA4F34;
  --white-color: #ffffff;
  --black-color: #000000;
}

.teamSection {
  padding-top: 1rem;
}
.teamSection .main-heading {
  color: var(--primary-color);
  line-height: 1.5rem;
  padding-bottom: 0.5rem;
  font-size: 35.3px;
  font-weight: 700;
  letter-spacing: -0.78px;
  line-height: 53px;
}
.teamSection .doctorTeam {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8rem;
  margin-left: 10px;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
}
.teamSection .doctorTeam .doctor-1 {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 1rem;
  box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.45);
  height: 740px;
  width: 400px;
}
.teamSection .doctorTeam .doctor-1 .doctorNameSection-1 {
  height: 600;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: grid;
  align-items: start;
}
.teamSection .doctorTeam .doctor-1 .doctorNameSection-1 .veerImg {
  height: 400px;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  overflow: hidden;
}
.teamSection .doctorTeam .doctor-1 .doctorNameSection-1 .doctorName-1 {
  position: relative;
}
.teamSection .doctorTeam .doctor-1 .doctorNameSection-1 .doctorName-1 h3 {
  font-size: 2rem;
  font-weight: 600;
  color: var(--primary-color);
  width: -moz-max-content;
  width: max-content;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 0;
}
.teamSection .doctorTeam .doctor-1 .doctorNameSection-1 .specialist-1 {
  position: relative;
}
.teamSection .doctorTeam .doctor-1 .doctorNameSection-1 .specialist-1 h5 {
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--primary-color);
  width: -moz-max-content;
  width: max-content;
  margin-top: 0px;
  margin-left: 10px;
  margin-bottom: 0;
}
.teamSection .doctorTeam .doctor-1 .detailsOfDoctor-1 p {
  color: var(--black-color);
  font-size: 17px;
  font-weight: 400;
  margin-left: 10px;
  margin-top: opx;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .teamSection .main-heading {
    font-size: 25px;
    margin-left: 10px;
  }
  .teamSection .doctorTeam {
    display: grid;
    gap: 2rem;
    align-items: center;
  }
  .teamSection .doctorTeam .doctor-1 {
    width: 100%;
  }
  .teamSection .doctorTeam .doctor-1 .doctorNameSection-1 .veerImg {
    max-width: 100%;
    max-height: 100%;
  }
  .teamSection .doctorTeam .doctor-1 .doctorNameSection-1 .doctorName-1 h3 {
    font-size: 1.5rem;
  }
  .teamSection .doctorTeam .doctor-1 .doctorNameSection-1 .specialist-1 h5 {
    font-size: 15px;
  }
}/*# sourceMappingURL=Ourteam.css.map */