:root {
    --primary-color: #CA4F34;
    --white-color: #ffffff;
    --black-color: #000000;
}

// Review.scss

.sildecontainer {
    display: flex;
    background-color: var(--white-color);
    // Add other styles for the slider container


    .one-review,
    .two-review {
        // Add styles for the individual review item
        padding: 20px;
        margin: 10px;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        // position: absolute;
        // background: linear-gradient(to top, rgba(255, 255, 255, 0.73), rgba(255, 255, 255, 0.615)), url(../../assets/images/review/quote.png);
        // background-repeat: no-repeat;
        background-color: var(--white-color);

        .review{
            font-size: 50px;
            font-weight: 600;
            color: var(--primary-color);
            margin-top: 0px;
        }
        .qoute-img {
            // Add styles for the quote image
            height: 50px;
            position: absolute;
            margin-top: 10px;
            text-align: start;
            left: 450px;
            top: 270px;
            z-index: 100;
        }

        .docName {
            // Add styles for the doctor's name
            font-size: 2rem;
            font-weight: bold;
            margin-top: 90px;
            color: var(--black-color);
        }

        .preivew {
            // Add styles for the review content
            margin-top: 10px;
            font-style: italic;
            width: 600px;
            z-index: 110;
        }

        .pName {
            // Add styles for the person's name
            margin-top: 10px;
            font-size: 18px;
            letter-spacing: 3px;
            color: var(--black-color);
        }
    }
}
@media (max-width:1024px){
    .sildecontainer{
        .one-review,.two-review{
            .qoute-img{
               left:200px;
               top:270px 
            }
        }
    }
}
@media (max-width:768px){
    .sildecontainer{
        .one-review,.two-review{
            .review{
                font-size: 40px;
                margin-top: 0px;
            }
            .docName{
                margin-top: 10px;
                z-index: 105;

            }
            .qoute-img{
               left:30px;
               top:200px 
            }
            .preivew{
                width: 250px;
                text-align: center;
            }
        }
    }
}