:root {
  --primary-color: #CA4F34;
  --white-color: #ffffff;
  --black-color: #000000;
}

a {
  text-decoration: none;
}

.contactSection {
  margin-top: 40px;
  text-decoration: none;
  background-color: transparent;
  justify-content: center;
  width: 100%;
  background: linear-gradient(to top, rgba(226, 217, 217, 0.73), rgba(220, 210, 210, 0.615)), url(../../assets/images/contactUs/image-1.jpg);
}
.contactSection .divSection {
  height: 100%;
  overflow-x: hidden;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.contactSection .divSection .heading {
  color: var(--primary-color);
  font-family: "Inter-Bold", Helvetica;
  font-size: 34.5px;
  font-weight: 700;
  letter-spacing: -0.76px;
  line-height: 51.8px;
  position: relative;
  white-space: nowrap;
  margin-left: 10px;
}
.contactSection .divSection .headerContact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contactSection .divSection .headerContact .p {
  color: var(--primary-color);
  font-family: "Inter-Medium", Helvetica;
  font-size: 51.8px;
  font-weight: 500;
  letter-spacing: -1.19px;
  position: relative;
  margin-top: 70px;
  margin-bottom: 0px;
}
.contactSection .divSection .headerContact .contacatDetails {
  color: var(--black-color);
  font-family: "Inter-Medium", Helvetica;
  font-size: 21.6px;
  font-weight: 500;
  width: 500px;
  letter-spacing: -0.48px;
  position: relative;
  text-align: center;
  margin-top: 10px;
}
.contactSection .divSection .main-body {
  display: flex;
  flex-direction: row;
}
.contactSection .divSection .main-body .contDet .address {
  display: flex;
  flex-direction: column;
}
.contactSection .divSection .main-body .contDet .address .localAddress {
  color: var(--primary-color);
  font-family: "Inter-Medium", Helvetica;
  font-size: 34.5px;
  font-weight: 500;
  letter-spacing: -0.76px;
  position: relative;
  white-space: nowrap;
  margin-top: 20px;
  margin-left: 20px;
}
.contactSection .divSection .main-body .contDet .address .detailedAddress {
  color: var(--black-color);
  font-family: "Inter-Medium", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.48px;
  position: relative;
  margin-left: 30px;
}
.contactSection .divSection .main-body .contDet .consultant {
  display: flex;
  flex-direction: column;
}
.contactSection .divSection .main-body .contDet .consultant .mainConsultant {
  color: var(--primary-color);
  font-family: "Inter-Medium", Helvetica;
  font-size: 34.5px;
  font-weight: 500;
  letter-spacing: -0.76px;
  position: relative;
  white-space: nowrap;
  margin-top: 20px;
  margin-left: 20px;
}
.contactSection .divSection .main-body .contDet .consultant .entry {
  color: var(--black-color);
  font-family: "Inter-Medium", Helvetica;
  letter-spacing: -0.48px;
  position: relative;
  margin-left: 30px;
}
.contactSection .divSection .main-body .contDet .consultant .entry .hehe {
  font-size: 21.6px;
  font-weight: 500;
  letter-spacing: -0.48px;
}
.contactSection .divSection .main-body .contDet .consultant .entry .hours {
  font-size: 18px;
  font-weight: 400;
}
.contactSection .divSection .main-body .contDet .timing {
  display: flex;
  flex-direction: column;
}
.contactSection .divSection .main-body .contDet .timing .visiting {
  color: var(--primary-color);
  font-family: "Inter-Medium", Helvetica;
  font-size: 34.5px;
  font-weight: 500;
  letter-spacing: -0.76px;
  position: relative;
  white-space: nowrap;
  margin-top: 20px;
  margin-left: 20px;
}
.contactSection .divSection .main-body .contDet .timing .watchHours {
  color: var(--black-color);
  font-family: "Inter-Medium", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.48px;
  position: relative;
  margin-left: 30px;
}
.contactSection .divSection .main-body .contDet .socialMedia {
  display: flex;
  flex-direction: column;
}
.contactSection .divSection .main-body .contDet .socialMedia .mainMedia {
  color: var(--primary-color);
  font-family: "Inter-Medium", Helvetica;
  font-size: 34.5px;
  font-weight: 500;
  letter-spacing: -0.76px;
  position: relative;
  white-space: nowrap;
  margin-top: 20px;
  margin-left: 20px;
}
.contactSection .divSection .main-body .contDet .socialMedia .social-Links {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
}
.contactSection .divSection .main-body .contDet .socialMedia .social-Links .imgInsta {
  position: relative;
  border-radius: 50px;
  margin-left: 40px;
  transition: transform 0.3s;
  cursor: pointer;
}
.contactSection .divSection .main-body .contDet .socialMedia .social-Links .imgInsta:hover {
  transform: scale(1.1);
}
.contactSection .divSection .main-body .contDet .socialMedia .social-Links .imgFace {
  position: relative;
  border-radius: 40px;
  margin-left: 40px;
  transition: transform 0.3s;
  cursor: pointer;
}
.contactSection .divSection .main-body .contDet .socialMedia .social-Links .imgFace:hover {
  transform: scale(1.1);
}
.contactSection .divSection .main-body .imgdiv .imgMap {
  height: 600px;
  position: relative;
  width: 700px;
  border-radius: 10px;
  transition: transform 0.3s;
  margin-top: 30px;
  border: transparent;
  cursor: pointer;
}
.contactSection .divSection .main-body .imgdiv .imgMap:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .contactSection .divSection .headerContact .p {
    font-size: 30px;
    text-align: center;
  }
  .contactSection .divSection .headerContact .contacatDetails {
    font-size: 15px;
    width: 200px;
  }
  .contactSection .divSection .main-body .contDet .address .localAddress {
    font-size: 30px;
  }
  .contactSection .divSection .main-body .contDet .address .detailedAddress {
    font-size: 17px;
  }
  .contactSection .divSection .main-body .contDet .consultant .mainConsultant {
    font-size: 30px;
  }
  .contactSection .divSection .main-body .contDet .consultant .hehe {
    font-size: 20px;
  }
  .contactSection .divSection .main-body .contDet .consultant .hours {
    font-size: 17px;
    position: relative;
    width: 250px;
  }
  .contactSection .divSection .main-body .contDet .timing .visiting {
    font-size: 30px;
  }
  .contactSection .divSection .main-body .contDet .timing .watchHours {
    font-size: 17px;
    margin-top: 10px;
  }
  .contactSection .divSection .main-body .contDet .socialMedia .mainMedia {
    font-size: 30px;
  }
  .contactSection .divSection .main-body .contDet .socialMedia .imgFace {
    height: 60px;
    width: 60px;
  }
  .contactSection .divSection .main-body .contDet .socialMedia .imgInsta {
    height: 60px;
    width: 60px;
  }
}/*# sourceMappingURL=Contact.css.map */