:root {
  --primary-color: #CA4F34;
  --white-color: #ffffff;
  --black-color: #000000;
  --grey-color: rgb(89, 89, 89);
}

.specialistsSection {
  background: linear-gradient(to top, rgba(255, 255, 255, 0.73), rgba(255, 255, 255, 0.615)), url(../../assets/images/specialist/bgimgspl.jpg.jpeg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.specialistsSection .mainPageHeading {
  font-size: 35px;
  font-weight: 700;
  color: var(--primary-color);
}
.specialistsSection .main-content {
  display: flex;
  flex-direction: column;
}
.specialistsSection .main-content .one-special,
.specialistsSection .main-content .two-special,
.specialistsSection .main-content .three-special {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
}
.specialistsSection .main-content .one-special img,
.specialistsSection .main-content .two-special img,
.specialistsSection .main-content .three-special img {
  margin-top: 20px;
  margin-right: 10px;
  height: 100px;
  width: 100px;
}
.specialistsSection .main-content .one-special .treatment .special-Under,
.specialistsSection .main-content .two-special .treatment .special-Under,
.specialistsSection .main-content .three-special .treatment .special-Under {
  font-size: 30px;
  font-weight: 600;
  color: var(--primary-color);
}
.specialistsSection .main-content .one-special .treatment p,
.specialistsSection .main-content .two-special .treatment p,
.specialistsSection .main-content .three-special .treatment p {
  font-size: 20px;
  font-weight: 400;
  color: var(--black-color);
}
.specialistsSection .main-content .one-special .treatment ul li,
.specialistsSection .main-content .two-special .treatment ul li,
.specialistsSection .main-content .three-special .treatment ul li {
  font-size: 20px;
  color: var(--black-color);
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .specialistsSection .main-content .one-special img,
  .specialistsSection .main-content .two-special img,
  .specialistsSection .main-content .three-special img {
    height: 40px;
    width: 40px;
  }
  .specialistsSection .main-content .one-special .treatment .special-Under,
  .specialistsSection .main-content .two-special .treatment .special-Under,
  .specialistsSection .main-content .three-special .treatment .special-Under {
    font-size: 30px;
  }
  .specialistsSection .main-content .one-special .treatment .p,
  .specialistsSection .main-content .two-special .treatment .p,
  .specialistsSection .main-content .three-special .treatment .p {
    font-size: 20px;
  }
  .specialistsSection .main-content .one-special .treatment .ul li,
  .specialistsSection .main-content .two-special .treatment .ul li,
  .specialistsSection .main-content .three-special .treatment .ul li {
    font-size: 30px;
    color: var(--primary-color);
  }
}/*# sourceMappingURL=Specialists.css.map */