:root {
  --primary-color: #CA4F34;
  --white-color: #ffffff;
  /* Import fonts here if needed */
}

.headerContainer {
  z-index: 2000;
  /* padding: 4rem 0 2rem; */
}
.headerContainer .header {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  padding: 20px;
  z-index: 1000;
  /* padding: 40px; */
  background-color: #ffffff;
  border: 1px solid #CA4F34;
  flex-direction: row;
}
.headerContainer .header .logoDiv img {
  border-radius: 50px;
  width: 50px;
  height: 50px;
  color: var(--white-color);
}
.headerContainer .title-desktop {
  color: var(--primary-color);
  font-size: 32px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  font-family: "Roboto", sans-serif;
  text-align: center;
  flex-grow: 1;
  flex: 1;
}
.headerContainer .title-mobile {
  display: none;
  /* Hide by default for desktop view */
}
@media screen and (max-width: 768px) {
  .headerContainer .header .logoDiv img {
    background: transparent;
    /* Adjust mobile logo styling here */
  }
  .headerContainer .title-desktop {
    display: none;
    /* Hide for mobile view */
  }
  .headerContainer .title-mobile {
    color: var(--primary-color);
    font-size: 30px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    font-family: "Roboto", sans-serif;
    text-align: center;
    display: block;
    /* Show for mobile view */
    flex-grow: 1;
    margin-left: 0px;
    margin-right: 20px;
  }
}/*# sourceMappingURL=Header.css.map */