:root {
    --primary-color: #CA4F34;
    --white-color: #ffffff;
}

.slideContainer {
    height: 500px;

}

.awssld__content>img {
    max-width: 100%;
    max-height: 100%;
    // background-position: center;
    // background-size: cover;
    // background-repeat: no-repeat;
    object-fit: fill;
}

@media (max-width:768px) {
    .slideContainer {
        height: 250px;

        .he {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            object-fit: cover;
        }
    }
}