:root {
    --primary-color: #CA4F34;
    --white-color: #ffffff;
    /* Import fonts here if needed */
}

.headerContainer {
    // position: fixed;
    z-index: 2000;
    /* padding: 4rem 0 2rem; */


    .header {
        display: flex;
        position: relative;
        justify-content: space-between;
        align-items: center;
        background: transparent;
        //width: 100%;
        padding: 20px;
        z-index: 1000;
        /* padding: 40px; */
        background-color: #ffffff;
        border: 1px solid #CA4F34;
        flex-direction: row;
    }

    .header .logoDiv img {
        border-radius: 50px;
        width: 50px;
        // margin-right: 10px;
        height: 50px;
        // background-color: var(--white-color);
        color: var(--white-color);
    }

    .title-desktop {
        color: var(--primary-color);
        font-size: 32px;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
        font-family: 'Roboto', sans-serif;
        text-align: center;
        flex-grow: 1;
        flex: 1;
    }

    .title-mobile {
        display: none;
        /* Hide by default for desktop view */
    }

    @media screen and (max-width:768px) {
        .header .logoDiv img {
            background: transparent;
            /* Adjust mobile logo styling here */
        }

        .title-desktop {
            display: none;
            /* Hide for mobile view */
        }

        .title-mobile {
            color: var(--primary-color);
            font-size: 30px;
            font-weight: 700;
            font-family: 'Poppins', sans-serif;
            font-family: 'Roboto', sans-serif;
            text-align: center;
            display: block;
            /* Show for mobile view */
            flex-grow: 1;
            margin-left: 0px;
            margin-right: 20px;
        }
    }
}