:root{
  --primary-color: #CA4F34;
  --white-color:#ffffff;
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&family=Roboto:wght@300;400;500&display=swap');   
}
.footer {
    width: 100%;
    height: 100px;
    background-color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
    margin-top: 480px;
    margin: auto;
    margin-top: auto;

    p {
      text-align: center;
      color:var(--white-color);
      font-size: 14px;
      span {
        //color: black;
        font-weight: 700;

        a {
          color: white;
        }
      }
    }
  }
  
  .socialMedia svg {
    color:var(--white-color);
    margin: 20px;
    font-size: 70px;
    cursor: pointer;
  }
 