:root {
  --primary-color: #CA4F34;
  --white-color: #ffffff;
}

.slideContainer {
  height: 500px;
}

.awssld__content > img {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: fill;
     object-fit: fill;
}

@media (max-width: 768px) {
  .slideContainer {
    height: 350px !important;
  }
  .slideContainer .he {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    -o-object-fit: cover;
       object-fit: cover;
  }
}/*# sourceMappingURL=Slide.css.map */