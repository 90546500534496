:root {
  --primary-color: #CA4F34;
  --white-color: #ffffff;
  --black-color:#000000;
}

.aboutSection {
  background-color: var(--primary-color);
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  top: 50px;
  width: 100%;
  left: 0;
  height: 600px;
  margin-bottom: 40px;
}
.aboutSection .main-heading {
  color: var(--white-color);
  font-family: "Inter-Bold", Helvetica;
  font-size: 32px;
  font-weight: 700;
  left: 60%;
  letter-spacing: -0.7px;
  line-height: 40px;
  position: absolute;
  top: 40px;
  white-space: nowrap;
  transform: translateX(-50%);
  /* Center the text */
  width: 178px;
}
.aboutSection .img-div .img-about {
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 48%;
  left: 0;
  overflow: hidden;
  z-index: 1;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.aboutSection .content-hospital {
  display: flex;
  flex-direction: column;
  color: var(--white-color);
  font-family: "Inter-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 50%;
  letter-spacing: -0.44px;
  line-height: 30px;
  position: absolute;
  margin-top: 90px;
  width: 50%;
  overflow: hidden;
  flex-grow: 1;
  z-index: 8;
}

@media (max-width: 768px) {
  .aboutSection {
    display: flex;
    flex-direction: column;
    height: 1100px;
  }
  .aboutSection .main-heading {
    text-align: center;
    /* Center text for mobile */
    left: 50%;
    top: 25px;
  }
  .aboutSection .img-div .img-about {
    position: absolute;
    background-position: center;
    width: 100%;
    margin-top: 100px;
    height: 400px;
  }
  .aboutSection .content-hospital {
    text-align: start;
    /* Center text for mobile */
    width: 100%;
    margin-top: 500px;
    padding: 0 0;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    z-index: 9;
    display: block;
    font-size: 17px;
    position: relative;
    left: 0;
    padding-left: 4px;
    overflow: hidden;
  }
}/*# sourceMappingURL=About.css.map */