:root {
  --primary-color: #CA4F34;
  --white-color: #ffffff;
  --black-color: #000000;
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&family=Roboto:wght@300;400;500&display=swap");
}

.navbarSection .navbar {
  background-color: var(--primary-color);
  /* Dark background color */
  color: var(--white-color);
  /* Text color */
  padding: 25px 0;
  /* Padding on top and bottom */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Optional box shadow */
}
.navbarSection .navbar .navbarContent {
  display: flex;
  /* Make it a flex container */
  justify-content: space-between;
  /* Equal space between Nav.Link items */
  width: 100%;
  /* Cover the entire width */
  flex-grow: 1;
}
.navbarSection .navbar .navbarContent :hover {
  color: #007bff;
  /* Change link color on hover (e.g., blue) */
}
.navbarSection .navbar .navbarContent .navItem {
  margin: auto;
  text-decoration: none;
  /* Remove underlines from links */
  font-weight: 600;
  /* Bold text */
  color: var(--white-color);
  /* Link text color */
  text-decoration: none;
  /* Remove underlines from links */
  flex: 1;
  /* Distribute available width equally among links */
  text-align: center;
  /* Center the link text horizontally */
  transition: color 0.3s;
  /* Smooth color transition on hover */
  box-sizing: border-box;
  /* Include padding and border in total width */
  border-radius: 10px;
  transition: transform 0.3s;
  cursor: pointer;
}
.navbarSection .navbar .navbarContent .navItem:hover {
  transform: scale(1.1);
}
.navbarSection .navbar .navbarContent .closeNavbar {
  display: none;
}
@media (max-width: 768px) {
  .navbarSection .navbar {
    position: absolute;
    height: -moz-max-content;
    height: max-content;
    padding: 1rem;
    width: 100%;
    top: 0;
    left: -500%;
    box-shadow: 0 2px 4px rgba(209, 209, 209, 0.904);
    transition: 0.5s ease;
    z-index: 1200;
  }
  .navbarSection .navbar .navbarContent {
    display: grid;
    grid-template-columns: 1fr;
    /* Adjust the number of columns as needed */
    gap: 1rem;
    /* Adjust the gap between grid items */
    text-align: center;
    width: 100%;
  }
  .navbarSection .navbar .navbarContent .navItem {
    display: block;
    padding: 1rem 0;
    color: var(--white-color);
    font-size: 0.9rem;
    font-weight: 600;
  }
  .navbarSection .navbar .navbarContent .navItem:hover {
    color: var(--white-color);
  }
  .navbarSection .navbar .navbarContent .closeNavbar {
    display: block;
    position: absolute;
    flex: 1;
    left: 2rem;
    top: 1rem;
    right: 2 rem;
    color: var(--white-color);
  }
  .navbarSection .navbar .navbarContent .closeNavbar:hover {
    color: var(--black-color);
  }
  .navbarSection .activeNavbar {
    left: 0;
  }
}
.navbarSection .toggleNavbar {
  display: none;
}
@media (max-width: 768px) {
  .navbarSection .toggleNavbar {
    display: block;
  }
  .navbarSection .toggleNavbar .icon {
    font-size: 40px;
    color: var(--primary-color);
    position: absolute;
    top: 1.5rem;
    right: 1rem;
    z-index: 1100;
    /* Ensure it appears above other content */
    cursor: pointer;
  }
  .navbarSection .toggleNavbar .icon:hover {
    color: var(--black-color);
  }
}/*# sourceMappingURL=Navigationbar.css.map */